<template>
  <v-card elevation="0" :loading="chartLoading" height="240">
    <v-card-text>
      <chart type="area" height="220" :options="chart.options" :series="chart.series" />
    </v-card-text>
  </v-card>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-03-31 15:37:07
   * @modify date 2021-03-31 15:37:07
   * @desc EarlyChart
   */
  export default {
    name: "EarlyChart",
    props: {
      workplans: Array,
      chartLoading: Boolean
    },
    data: vm => ({
      chart: {
        options: {
          chart: {
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: "smooth"
          },
          fill: {
            opacity: 1
          },
          labels: [],
          yaxis: {
            min: 0,
            show: false
          },
          xaxis: {
            type: "datetime"
          },
          colors: ["#008FFB"],
          title: {
            text: "",
            offsetX: 30,
            style: {
              fontSize: "24px",
              cssClass: "apexcharts-yaxis-title"
            }
          },
          subtitle: {
            text: vm.$t("dashboard.access.total_leaver_average"),
            offsetX: 30,
            style: {
              fontSize: "14px",
              cssClass: "apexcharts-yaxis-title"
            }
          }
        },
        series: []
      }
    }),
    watch: {
      workplans: {
        deep: true,
        handler(workplans) {
          this.renderChart(workplans);
        }
      }
    },
    methods: {
      renderChart(workplans) {
        let timesheets = workplans.filter(
          item => !item.countAsOvertime && item.assignedShifts.length && !item.leaveRequest && item.timesheets.length
        );
        let earlies = [];
        timesheets.forEach(workplan => {
          let early = workplan.timesheets.filter(item => item.earlyClockOutTimeInMinutes);
          if (early.length) {
            earlies.push({
              date: workplan.date,
              totalMinutes: early.reduce((acc, item) => acc + item.earlyClockOutTimeInMinutes, 0) / early.length
            });
          }
        });
        const earliesAverage = (earlies.reduce((acc, item) => acc + item.totalMinutes, 0) / earlies.length).toFixed(0);
        this.chart.options = {
          ...this.chart.options,
          labels: earlies.map(item => item.date),
          title: {
            text: isNaN(earliesAverage)
              ? 0 + ` ${this.$t("dashboard.access.short_minute")}`
              : earliesAverage + ` ${this.$t("dashboard.access.short_minute")}`
          }
        };
        this.chart.series = [
          {
            name: this.$t("dashboard.access.early_minute"),
            data: earlies.map(item => item.totalMinutes)
          }
        ];
      }
    },
    created() {
      this.renderChart(this.workplans);
    }
  };
</script>

<style></style>
